import React from "react"
import { Link } from "gatsby"
import approfondimentiCardStyles from "../../styles/cards/approfondimenti-card.module.scss"

const ApprofondimentiCard = ({ approfondimento, location }) => {
  const isCardValid =
    approfondimento.node.titolo &&
    approfondimento.node.slug &&
    approfondimento.node.categoria
  if (isCardValid) {
    return (
      <li
        key={approfondimento.node.strapiId}
        className={
          location.pathname === "/meditazioni-e-approfondimenti"
            ? approfondimentiCardStyles.card
            : approfondimentiCardStyles.cardSlug
        }
      >
        <Link
          to={
            location.pathname === "/meditazioni-e-approfondimenti"
              ? `${approfondimento.node.slug}`
              : `/meditazioni-e-approfondimenti/${approfondimento.node.slug}`
          }
        >
          <span className={approfondimentiCardStyles.categoria}>
            {approfondimento.node.categoria}
          </span>
          <p className={approfondimentiCardStyles.nome}>
            {approfondimento.node.titolo}
          </p>
          <span className={approfondimentiCardStyles.autore}>
            {approfondimento.node.autore.nome}
          </span>
        </Link>
      </li>
    )
  }
  return null
}

export default ApprofondimentiCard
