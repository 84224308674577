import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/common/layout"
import FadeInAnimation from "../components/animations/fade"
import ApprofondimentiCard from "../components/cards/approfondimentiCard"
import approfondimentiStyles from "../styles/pages/approfondimenti.module.scss"

const Approfondimenti = ({ data, location }) => {
  const approfondimenti = data.allStrapiApprofondimenti.edges || []

  const [filter, setFilter] = useState("all")

  const handleFilter = (category) => {
    setFilter(category)
  }

  const filteredApprofondimenti =
    filter === "all"
      ? approfondimenti
      : approfondimenti.filter((approfondimento) =>
          approfondimento.node.categoria
            .toLowerCase()
            .includes(filter.toLowerCase())
        )

  return (
    <Layout
      pageMeta={{
        title: "Meditazioni e Approfondimenti",
        keywords: [
          "lettera apostolica",
          "commenti alle letture",
          "testimonianze di fede cristiana",
        ],
        description: "Meditazioni e approfondimenti di vita cristiana ",
        link: "/meditazioni-e-approfondimenti",
      }}
      location={location}
      cssClass={approfondimentiStyles.pageWrapper}
    >
      <FadeInAnimation direction="right">
        <h2 className={approfondimentiStyles.title}>
          Meditazioni e Approfondimenti
        </h2>
      </FadeInAnimation>
      <FadeInAnimation direction="left">
        <div className={approfondimentiStyles.filters}>
          <li
            className={
              filter === "all"
                ? `${approfondimentiStyles.filter} ${approfondimentiStyles.active}`
                : `${approfondimentiStyles.filter}`
            }
            onClick={() => handleFilter("all")}
            role="presentation"
          >
            tutto
          </li>
          <li
            className={
              filter === "testo"
                ? `${approfondimentiStyles.filter} ${approfondimentiStyles.active}`
                : `${approfondimentiStyles.filter}`
            }
            onClick={() => handleFilter("testo")}
            role="presentation"
          >
            testo
          </li>
          <li
            className={
              filter === "video"
                ? `${approfondimentiStyles.filter} ${approfondimentiStyles.active}`
                : `${approfondimentiStyles.filter}`
            }
            onClick={() => handleFilter("video")}
            role="presentation"
          >
            video
          </li>
          <li
            className={
              filter === "audio"
                ? `${approfondimentiStyles.filter} ${approfondimentiStyles.active}`
                : `${approfondimentiStyles.filter}`
            }
            onClick={() => handleFilter("audio")}
            role="presentation"
          >
            audio
          </li>
        </div>{" "}
      </FadeInAnimation>
      <FadeInAnimation direction="up">
        <ul className={approfondimentiStyles.approfondimenti}>
          {filteredApprofondimenti.length ? (
            filteredApprofondimenti.map((approfondimento) => (
              <ApprofondimentiCard
                key={approfondimento.node.strapiId}
                approfondimento={approfondimento}
                location={location}
              />
            ))
          ) : (
            <p className={approfondimentiStyles.text}>
              non ci sono ancora contributi in questa categoria
            </p>
          )}
        </ul>
      </FadeInAnimation>
    </Layout>
  )
}

export default Approfondimenti

export const query = graphql`
  query ApprofondimentiQuery {
    allStrapiApprofondimenti(sort: { fields: updatedAt, order: DESC }) {
      edges {
        node {
          strapiId
          autore {
            nome
          }
          slug
          titolo
          categoria
          multimedia {
            testo
            file {
              publicURL
              ext
            }
          }
        }
      }
    }
  }
`
